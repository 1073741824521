import { axiosInstance, scbAxiosInstance } from "../services";
import { useAlertStore, loaderStore } from "../stores";

export const buyBid = async (body) => {
    const resp = await scbAxiosInstance.post(`/reseller/orders/checkout` , body)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}


export const hybridBuy = async (body, params = {}) => {
    const loader = loaderStore();
    const alertStore = useAlertStore();
    const resp = await axiosInstance.post(`/api/bids/check_out`, { ...body }, params)
    .then(res => {
        loader.setLoading(false, [])
        return res;
    }).catch((err) => {
        if (err?.response?.data?.errors) {
            loader.setLoading(false, [])
            let errObj = (typeof err.response.data.errors === 'object') ? Object.values(err.response.data.errors).join('') : err.response.data.errors;            
            alertStore.setAlertMessage("error", errObj, true);
        } else if (err?.response?.status === 500) {
            alertStore.setAlertMessage("error", "Something went wrong, Please try again after some time.", true);
        }
        return err?.response
    })
    return resp;
}

export const updatLotQuantity = async (body, params = {}) => {
    const alertStore = useAlertStore();
    const resp = await axiosInstance.post(`/api/carts/check_out`, { ...body }, params)
    .then(res => {
        return res;
    }).catch((err) => {
        if (err?.response?.data?.errors) {
            let errObj = (typeof err.response.data.errors === 'object') ? Object.values(err.response.data.errors).join('') : err.response.data.errors;            
            alertStore.setAlertMessage("error", errObj, true);
        } else if (err?.response?.status === 500) {
            alertStore.setAlertMessage("error", "Something went wrong, Please try again after some time.", true);
        }
        return err?.response
    })
    return resp;
}


export const moqCartBuy = async (body, params = {}) => {
    const alertStore = useAlertStore();
    const resp = await axiosInstance.post(`/api/carts/check_out`, { ...body }, params)
    .then(res => {
        return res;
    }).catch((err) => {
        if (err?.response?.data?.errors) {
            let errObj = (typeof err.response.data.errors === 'object') ? Object.values(err.response.data.errors).join('') : err.response.data.errors;            
            alertStore.setAlertMessage("error", errObj, true);
        } else if (err?.response?.status === 500) {
            alertStore.setAlertMessage("error", "Something went wrong, Please try again after some time.", true);
        }
        return err?.response
    })
    return resp;
}

export const moqUpdateLotQty = async (body, params = {}) => {
    const alertStore = useAlertStore();
    const resp = await axiosInstance.patch(`/api/carts/update_quantity`, { ...body }, params)
    .then(res => {
        return res;
    }).catch((err) => {
        if (err?.response?.data?.errors) {
            let errObj = (typeof err.response.data.errors === 'object') ? Object.values(err.response.data.errors).join('') : err.response.data.errors;            
            alertStore.setAlertMessage("error", errObj, true);
        } else if (err?.response?.status === 500) {
            alertStore.setAlertMessage("error", "Something went wrong, Please try again after some time.", true);
        }
        return err?.response
    })
    return resp;
}

export const moqRemoveLot = async (body, params = {}) => {
    const alertStore = useAlertStore();
    const resp = await axiosInstance.patch(`/api/carts/remove_cart_lot`, { ...body }, params)
    .then(res => {
        return res;
    }).catch((err) => {
        if (err?.response?.data?.errors) {
            let errObj = (typeof err.response.data.errors === 'object') ? Object.values(err.response.data.errors).join('') : err.response.data.errors;            
            alertStore.setAlertMessage("error", errObj, true);
        } else if (err?.response?.status === 500) {
            alertStore.setAlertMessage("error", "Something went wrong, Please try again after some time.", true);
        }
        return err?.response
    })
    return resp;
}


export const getAllCart = async () => {
    const loaderState = loaderStore();
    const resp = await axiosInstance.get(`/api/carts/list_all_cart`)
    .then(res => {
        loaderState.setLoading(false, []);
        return res;
    }).catch((e) => {
        loaderState.setLoading(false, []);
        return e.response
    })
    return resp;
}


export const fetchCartLots = async (id) => {
    const resp = await axiosInstance.get(`/api/carts/list_all_lots?cart_number=${id}`)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}


export const moqAddToCart = async (body) => {
    const resp = await axiosInstance.post(`/api/carts` , body)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}


export const fetchAllScbProducts = async (params) => {
    const resp = await scbAxiosInstance.get(`/api/external_service/get_manifest_detail`, { params })
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}


export const fetchOpenLotProducts = async (id, bid_id, isInterval) => {
    let url = `/api/external_service/get_lot_details?id=${id}&bid_id=${bid_id}`;
    if(isInterval) {
        url = url + `&autorefresh=active`
    }

    const resp = await scbAxiosInstance(url)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}
import { defineStore } from "pinia";
import { axiosInstance, scbAxiosInstance } from "../services";
import { usePaginationStore } from "./pagination.store";
import { useFilter } from "./filter.store";
import { getLotFilterQuery } from "../misc/index";
import {
  formatLots,
  formatLotDetails,
  formatLotManifestDetail,
  formatCurrency,
} from "../misc/formatter";
import { useAlertStore } from "./alert.store";
import { scbLotSortOptions, statusList } from "../static";
import {
  buyBid,
  hybridBuy,
  fetchOpenLotProducts,
  fetchAllScbProducts,
  getAllCart,
  moqAddToCart,
  moqCartBuy,
  moqUpdateLotQty,
  moqRemoveLot,
} from "../Actions/ProductActions";
import axios from "axios";
import { loaderStore } from "./loader.store";
import { useAuth } from "./auth.store";
import { useMetaStore } from '../stores/metastore.store';

export const uselotProducts = defineStore({
  id: "lotProducts",
  state: () => ({
    cartProduct: [],
    openCartProduct: [],
    error: "",
    loading: false,
    success: "",
    lotSummary: "",
    top_Brand: [],
    carts: [],
    top_Category: [],
    allProductsForDetailsPage: [],
    buyNowSummary: "",
    cartBuyNowSummary: {},
    organization_id: "",
    moq_lot_pricings: [],
    order_details: "",
    active_lots: 0,
    open_active_lots: 0,
    UUID_Params: "",
    amazonRibbon: '',
  }),

  actions: {
    setAllProductsForDetailsPage() {
      this.allProductsForDetailsPage = [];
    },
    setError() {
      this.error = "";
    },
    setCartProducts(val = []) {
      this.cartProduct = val;
    },
    setActiveLots(val = 0) {
      this.active_lots = val;
    },
    setOpenActiveLots(val = 0) {
      this.open_active_lots = val;
    },
    setSuccess() {
      this.success = "";
    },
    setOrganizationId(id) {
      this.organization_id = id;
    },
    setAmazonRibbon(value) {
      this.amazonRibbon = value;
    },
    setUUIDParams(uuid) {
      this.UUID_Params = uuid;
    },

    async fetchAllBidReferesh(data) {
      let formData = new FormData();
      this.openCartProduct.map((pr, index) => {
        formData.append(`bid_master_ids[]`, pr.bid_id)
      })
      scbAxiosInstance.post('/all_bids_refersh', formData)
        .then(resp => {
          this.source = null;
          if (resp.status === 200) {
            const newProducts = this.openCartProduct.map(product => {
              const dataObj = resp.data;
              const rec = dataObj.forward_bid_details[product.bid_id];

              let increment_slab = rec?.increment_slab || 0;
              let my_price = rec.your_bid;
              let highest_price = rec?.heighest_bid || 0;

              let floor_price = rec.starting_price || 0;
              let currentBid = highest_price ? highest_price : floor_price;

              let current_bid_price = (my_price && (my_price > highest_price)) ? (my_price + increment_slab) : (highest_price && (highest_price > floor_price)) ? (highest_price + increment_slab) : (increment_slab + floor_price)
              const rank = rec.rank;
              const color = (my_price && (my_price > 0)) ? (['Winning', 'Won'].indexOf(rank.trim()) > -1 ? 'win' : 'lose') : 'no-bid';

              return {
                ...product,
                color: color,
                rank: rank?.trim() ? `(${rank.trim()})` : '',
                place_bid_amount: (current_bid_price.toString()),
                currentBid: (currentBid),
                bidRemainingTime: rec.bid_remaining_time,
                end_date: new Date(rec.end_date * 1000),
                start_date: new Date(rec.start_date * 1000),
                status: rec.status,
                bid_price: (rec.your_bid),
                bid_amount: (rec.your_bid),
                increment_slab: (rec.increment_slab),
                highest_price: rec.heighest_bid,
                floor_price: (rec.starting_price)
              }
            })
            this.openCartProduct = newProducts;
          }
        }).catch(err => {
          this.source = null;
          this.error = {
            id: "bid_price_error",
            message: err?.response?.data?.errors,
          };
        })
    },

    async setLotProducts(data = {}, isInterval = false) {
      if (this.source) {
        this.source.cancel("Operation canceled due to new request.");
      }

      // Create a new cancel token for the current request
      this.source = axios.CancelToken.source();

      const paginationStore = usePaginationStore();
      const filterStore = useFilter();
      const loader = loaderStore();
      const metaStore = useMetaStore();
      const marketplaceContent = metaStore.marketplaceContent;
      const marketplaceName = metaStore.marketplaceName;

      if ((filterStore.priceRange[0] == data.search?.priceRange?.[0]) && (filterStore.priceRange[1] == data.search?.priceRange?.[1])) {
        delete data.search.priceRange;
        delete data.priceFrom;
        delete data.priceTo;
      }

      data.per_page = data.per_page || paginationStore.perPage;

      if (data?.search?.sortBy) {
        data.search.sort_by = data.search?.sortBy;
      }

      if (isInterval) {
        data.autorefresh = 'active'
      }
      // data.organization_name = this.organization_id;
      // if (data.search) {
      //   data.search.organization_name = this.organization_id;
      // }

      if (marketplaceContent === 'private') {
        data.organization_name = marketplaceName
      } else {
        data.organization_name = this.organization_id;
        if (data.search) {
          data.search.organization_name = this.organization_id;
        }
      }

      await axiosInstance
        .post("/api/lot_publishes/filter", data, {
          cancelToken: this.source.token,
        })
        .then((response) => {
          loader.setLotLoading(false);
          this.source = null;
          if (response.status === 200) {
            this.cartProduct = formatLots(response.data.results);
            this.active_lots = response.data.active_lots;
            paginationStore.setTotalItems(response.data.meta.total_count);
            paginationStore.setPage(response.data.meta.current_page)
          } else {
            this.cartProduct = [];
          }
        })
        .catch((err) => {
          loader.setLotLoading(false);
          this.source = null;
          if (err?.response?.status) {
            this.cartProduct = [];
            this.active_lots = 0;
            paginationStore.setTotalItems(0);
            paginationStore.setPage(1);
            if (err.response.data.errors !== "Lots not present in the system") {
              filterStore.$reset();
            }
          }
          this.error = {
            id: "Lot_products",
            type: "lotproductsError",
            message: err?.response?.data?.errors || "Something went wrong"
          }
        });
    },

    async placeBid(details, bid_id) {
      const loader = loaderStore();
      const alertStore = useAlertStore();

      let response;
      if (bid_id) {
        const bidDetails = {
          bid_id: details.id,
          bid_price: details.bid_price,
        };
        response = await scbAxiosInstance.post(
          `/api/external_service/place_bid`,
          bidDetails,
          { params: { uuid: this.UUID_Params } }
        );
      } else {
        try {
          const bidDetails = {
            lot_publish_id: details.id,
            bid_price: details.bid_price,
          };
          response = await axiosInstance.post(
            `/api/bids/place_bid`,
            bidDetails,
            { params: { uuid: this.UUID_Params } }
          );
        } catch (err) {
          loader.setLoading(false);
          alertStore.setAlertMessage("error", err.response.data.errors, true);
          this.error = {
            id: "bid_price_error",
            message: err?.response?.data?.errors,
          };
        }
      }

      if (response.status === 200 && !response.data.error) {
        loader.setLoading(false);
        this.success = {
          id: "bid_price_submit",
          message: "Congratulations",
        };

        if (bid_id) {
          this.getOpenLotProducts(getLotFilterQuery(), true)
        } else {
          this.setLotProducts(getLotFilterQuery(), true)
        }

        this.lotSummary.bid_price = (
          response.data?.bid_detail?.lot_publish.bid_amount ||
          response.data?.bid_price
        );
        this.lotSummary.rank =
          response.data?.bid_detail?.lot_publish?.rank || "";

      } else {
        alertStore.setAlertMessage("error", response.data.error, true);
        this.error = {
          id: "bid_price_error",
          message: response.data.error,
        };
      }
    },

    async checkoutCart(buyDetails) {
      const loader = loaderStore();
      const buyResp = await moqCartBuy(
        { ...buyDetails },
        { params: { uuid: this.UUID_Params } }
      );
      if (buyResp.status === 200) {
        loader.setLoading(false)
        let orderDetails = buyResp.data?.buy_now_summaries[0];
        orderDetails.orderIds = buyResp.data?.buy_now_summaries.map(a => a.order_number).join(',');
        this.order_details = orderDetails;
        this.success = {
          id: "checkout_submit",
          message: "Congratulations",
        };
        this.lotSummary.status = "Completed";
      }
    },

    async updateLotQty(payload) {
      const loader = loaderStore();
      const resp = await moqUpdateLotQty(
        { ...payload }
      );
      return resp;
    },

    async removeLot(payload) {
      const loader = loaderStore();
      const resp = await moqRemoveLot(
        { ...payload }
      );
      return resp;
    },

    async checkoutCart(buyDetails) {
      const loader = loaderStore();
      const buyResp = await moqCartBuy(
        { ...buyDetails },
        { params: { uuid: this.UUID_Params } }
      );
      if (buyResp.status === 200) {
        loader.setLoading(false)
        let orderDetails = buyResp.data?.buy_now_summaries[0];
        orderDetails.orderIds = buyResp.data?.buy_now_summaries.map(a => a.order_number).join(',');
        this.order_details = orderDetails;
        this.success = {
          id: "checkout_submit",
          message: "Congratulations",
        };
        this.lotSummary.status = "Completed";
      }
    },

    async checkoutBuyBid(buyDetails, bid_id) {
      const loader = loaderStore();
      if (bid_id) {
        let body = {
          bid_id: bid_id,
          payment_mode: buyDetails.payment_mode,
          payment_screen: "BuyNow",
          payment_type: this.buyNowSummary?.payment_type?.[0]?.id,
        };
        const buyResp = await buyBid(body);
        if (buyResp.status === 200 && !buyResp.data?.error) {
          loader.setLoading(false)
          this.detailsBidRefresh(bid_id)
          this.order_details = buyResp.data?.order;
          this.success = {
            id: "checkout_submit",
            message: "Congratulations",
          };
          this.lotSummary.status = "Completed";
        } else {
          alert(buyResp.data?.error);
        }
        loader.setLoading(false)
      } else {
        const hybridBuyResp = await hybridBuy(
          { ...buyDetails },
          { params: { uuid: this.UUID_Params } }
        );
        if (hybridBuyResp.status === 200) {
          loader.setLoading(false)
          this.order_details = hybridBuyResp.data?.value;
          this.success = {
            id: "checkout_submit",
            message: "Congratulations",
          };
          this.lotSummary.status = "Completed";
        }
      }
    },

    async fetchCarts() {
      const resp = await getAllCart();
      if (resp.status === 200) {
        this.carts = resp.data.carts;
      }
    },

    async addLotToCart(lot_publish_id, quantity) {
      const alertStore = useAlertStore();
      const loader = loaderStore();
      loader.setCartLoader(true);
      const resp = await moqAddToCart(
        { lot_publish_id: lot_publish_id, quantity: quantity }
      );

      loader.setCartLoader(true);
      if (resp.status === 200) {
        loader.setCartLoader(false);
        this.fetchCarts();
        alertStore.setAlertMessage("success", "Lot successfully added to cart.", true);
      } else if (resp.data.errors) {
        loader.setCartLoader(false);
        let errObj = (typeof resp.data.errors === 'object') ? Object.values(resp.data.errors).join('') : resp.data.errors;
        alertStore.setAlertMessage("error", errObj, true);
      }

      return resp;
    },

    async getCartBuyDialogSummary(cartId) {
      const alertStore = useAlertStore();
      let errored;
      await axiosInstance
        .post(`/api/carts/buy_now_summaries`, {
          cart_number: cartId,
        })
        .then((response) => {
          if (response.status === 200) {
            let buyNowSummaryObj = response.data.buy_now_summaries;
            buyNowSummaryObj.payment_options = buyNowSummaryObj.payment_options?.filter(a => (a.value.toLocaleLowerCase().match('neft') || a.value.toLocaleLowerCase().match('rtgs')))
            this.cartBuyNowSummary = buyNowSummaryObj;
          } else {
            errored = true
          }
        })
        .catch((err) => {
          errored = true;
          const alertStore = useAlertStore();
          if (err.response.data.errors) {
            let errObj = (typeof err.response.data.errors === 'object') ? Object.values(err.response.data.errors).join('') : err.response.data.errors;
            alertStore.setAlertMessage("error", errObj, true);
          } else if (err.response.status === 500) {
            alertStore.setAlertMessage("error", "Something went wrong", true);
          }
        });
      return errored;
    },

    async getBuyDialogSummary(lot_publish_id, bid_id, qty = '') {
      const alertStore = useAlertStore();
      let errored;
      if (bid_id) {
        let url1 = `/api/external_service/get_logistics_providers?bid_id=${bid_id}&payment_screen=BuyNow`;
        let url2 = "/api/external_service/get_neft_details";
        let request1 = scbAxiosInstance.get(url1);
        let request2 = scbAxiosInstance.get(url2);
        let [paymentDetails, bankDetails] = await Promise.all([
          request1,
          request2,
        ]);
        const isErrored = (paymentDetails?.data?.error || bankDetails?.data?.error);
        if (paymentDetails.status === 200 && bankDetails.status === 200) {
          if (isErrored) {
            errored = true;
            alertStore.setAlertMessage("error", isErrored, true);
            this.error = {
              id: "bid_price_error",
              message: isErrored,
            };
          } else {
            let data = {
              bank_details: bankDetails.data,
              payment_options:
                paymentDetails.data?.payment_types?.[0]?.payment_modes.filter(a => (a.value.toLocaleLowerCase().match('neft') || a.value.toLocaleLowerCase().match('rtgs'))),
              lot_publish: this.lotSummary,
              payment_type: paymentDetails.data?.payment_types,
            };
            this.buyNowSummary = data;
          }
        }
      } else {
        let queryData = { lot_publish_id, uuid: this.UUID_Params };
        if (qty) { queryData.quantity = qty }
        await axiosInstance
          .get(`/api/bids/buy_now_summary`, {
            params: queryData,
          })
          .then((response) => {
            if (response.status === 200) {
              response.data.buy_now_summary.payment_options = response.data.buy_now_summary.payment_options?.filter(a => (a.value.toLocaleLowerCase().match('neft') || a.value.toLocaleLowerCase().match('rtgs')))
              this.buyNowSummary = response.data.buy_now_summary;
            } else {
              errored = true
            }
          })
          .catch((err) => {
            errored = true;
            const alertStore = useAlertStore();
            if (err.response.data.errors) {
              alertStore.setAlertMessage("error", err?.response?.data?.errors, true);
            } else if (err.response.status === 500) {
              alertStore.setAlertMessage("error", "Something went wrong", true);
            }
          });
      }
      return errored;
    },

    async getlotDetail(id, isInterval = false) {
      const loader = loaderStore();
      this.error = '';
      let lotParams = { uuid: this.UUID_Params }
      if (isInterval) {
        lotParams.autorefresh = 'active'
      }
      const rec = this.cartProduct.find(a => a.id == id)
      if (rec) {
        const dataObj = formatLotDetails({
          lot_summary: {
            ...rec,
            mrp: rec.bid_mrp,
            bid_value: rec.increment_slab,
            bid_price: rec.bid_amount,
            bid_remaining_time: rec.bidRemainingTime,
          },
          moq_lot_pricings: rec.bidding_method === 'moq' ? [] : null
        })
        this.lotSummary = dataObj.lot_summary;
      }

      axiosInstance.get(`/api/lot_publishes/${id}/lot_details`, { params: lotParams })
        .then((response) => {
          if (response.status === 200) {
            loader.setLoading(false, [])
            let formatedData = formatLotDetails(response.data);
            this.setSuccess();
            const lotData = { available_quantity: response.data.available_quantity, ...formatedData.lot_summary };
            (this.lotSummary = lotData),
              (this.top_Brand = formatedData.top_Brand),
              (this.moq_lot_pricings = formatedData.moq_lot_pricings),
              (this.top_Category = formatedData.top_Category);

            this.cartProduct = this.cartProduct.map(a => {
              if (a?.id === id) {
                return {
                  ...a,
                  bid_value: lotData.increment_slab,
                  bid_amount: lotData.bid_price,
                }
              }

              return a
            })

          }
        })
        .catch((err) => {
          const loader = loaderStore();
          loader.setLoading(false)
          const alertStore = useAlertStore();
          // alertStore.setAlertMessage("error", err?.response?.data?.errors, true);
          this.error = {
            id: "Lot_details",
            type: "lotDetailsError",
            message: err?.response?.data?.errors || "Something went wrong"
          }
        })
    },

    async getAllProducts(id, param_uuid) {
      const loader = loaderStore();
      const paginationStore = usePaginationStore();
      loader.setProductsLoader(true)
      axiosInstance
        .get(`/api/lot_publishes/${id}/fetch_lot_inventories`, {
          params: {
            per_page: paginationStore.perPage,
            page: paginationStore.productPage,
            uuid: param_uuid,
          },
        })
        .then(async (response) => {
          loader.setProductsLoader(false)
          if (response.status === 200) {
            this.allProductsForDetailsPage = formatLotManifestDetail(
              response.data.all_products
            );
            await paginationStore.setProductPage(response.data.meta.current_page);
            await paginationStore.setProductTotalItems(response.data.meta.total_count);
          }
        })
        .catch((err) => {
          loader.setProductsLoader(false)
          loader.setLoading(false)
          this.allProductsForDetailsPage = [];
          paginationStore.setProductTotalItems(0);
          paginationStore.setProductPage(1);
        });
    },

    async getAllSCBProducts(id) {
      const loader = loaderStore();
      loader.setProductsLoader(true)

      const paginationStore = usePaginationStore();
      let params = {
        id: id,
        page_number: paginationStore.productPage,
        page_size: paginationStore.perPage,
      };

      const response = await fetchAllScbProducts(params);
      loader.setProductsLoader(false)
      if (response?.status === 200) {
        this.allProductsForDetailsPage = formatLotManifestDetail(
          response.data.external_service
        );
        paginationStore.setProductTotalItems(response.data.meta.total);
      } else {
        this.allProductsForDetailsPage = [];
        paginationStore.setProductTotalItems(0);
        paginationStore.setProductPage(1);
      }
    },

    async submitFeedbacks(id, buyer_feedback, bid_id) {
      let response;
      const params = { params: { uuid: this.UUID_Params } }
      let url = bid_id ? `/api/lots/${bid_id}/submit_feedback` : `/api/lot_publishes/${id}/buyer_feedbacks`;
      let data = bid_id ? { demand_request: buyer_feedback } : { buyer_feedback };
      response = bid_id ? await scbAxiosInstance.post(url, data, params) : await axiosInstance.post(url, data, params)

      if (response.status === 200 && !response?.data?.error) {
        this.success = {
          id: "buyer_feedbacks",
          message: "Thank You!",
        };
      } else {
        this.error = {
          id: "Lot_details",
          message: response?.data?.error
        };
      }
    },

    async trackManifestDownloads(bidId, id) {
      const resp = bidId ? scbAxiosInstance.get(`/api/external_service/download_manifest_file?id=${bidId}`) : axiosInstance.get(`/api/lot_publishes/${id}/download_manifest`)
    },
    async sendManifestURL(id, body, type, bid_id) {
      let res, SCBbody = {};
      let url = bid_id ? '/api/external_service/' : `/api/lot_publishes/${id}/`;
      url = `${url}/${type === "whatsapp" ? "whatsapp_manifest" : "email_manifest"}`;

      SCBbody = type === "whatsapp" ? { bids_master_id: bid_id, to: body.to, download_url: body.download_url } : { id: id, to: body.to };

      res = bid_id ? await scbAxiosInstance.post(url, SCBbody) : await axiosInstance.post(url, { ...body })

      if (res.status === 200) {
        this.success = {
          id: "send_manifestURL_success",
          message: `Hello!! A link will be received shortly to ${type === "whatsapp" ? "whatsapp number" : "the email address"
            } : ${body.to}`,
          manifestType: type,
        };
      }
    },

    async getOpenLotProducts(data = {}, isInterval = false) {
      const loader = loaderStore();
      try {
        if (!this.source) {
          // this.source.cancel("Operation canceled due to new request.");
          // Create a new cancel token for the current request
          this.source = axios.CancelToken.source();

          const paginationStore = usePaginationStore();
          data.sort_by = data.sort

          let body = {
            ...data,
            remarketing_json: true
          };

          if (this.organization_id) {
            body.organization_id = this.organization_id;
          }
          const filterStore = useFilter();

          if ((filterStore.priceRange[0] === data.search?.priceRange?.[0]) && (filterStore.priceRange[1] === data.search?.priceRange?.[1])) {
            delete data.search.priceRange;
            delete data.search.priceFrom;
            delete data.search.priceTo;
          }

          delete body.lot_type
          if (isInterval) {
            body.autorefresh = 'active'
            this.fetchAllBidReferesh();
          } else {
            scbAxiosInstance
              .post("/api/external_service/search_lot", body, {
                cancelToken: this.source.token,
              })
              .then((response) => {
                this.source = null;
                loader.setLotLoading(false);
                this.openCartProduct = formatLots(response.data.external_service);
                this.open_active_lots = response.data.meta.active_lots;
                paginationStore.setTotalItems(response.data.meta.total);
              }).catch((e) => {
                this.source = null;
                const loader = loaderStore();
                this.openCartProduct = [];
                this.open_active_lots = 0;
                loader.setLoading(false)
              })
          }
        }
      } catch {
        this.source = null;
      }
    },

    async fetchBidRefreshDetails(id) {
      let formData = new FormData();
      formData.append(`bid_master_ids[]`, id)

      scbAxiosInstance.post('/all_bids_refersh', formData)
        .then(resp => {
          if (resp.status === 200) {
            const dataObj = resp.data.forward_bid_details;
            const rec = dataObj[id];
            const color = rec.your_bid > 0 ? ((rec.your_bid >= rec.heighest_bid) ? 'win' : 'lose') : 'no-bid';
            const currentBid = rec.heighest_bid ? rec.heighest_bid : rec.starting_price;
            let current_bid_price = (rec.your_bid && (rec.your_bid > rec.heighest_bid)) ? (rec.your_bid + rec.increment_slab) : (rec.heighest_bid && (rec.heighest_bid > rec.starting_price)) ? (rec.heighest_bid + rec.increment_slab) : (rec.increment_slab + rec.starting_price)
            if (rec) {
              this.setSuccess();
              this.lotSummary = {
                ...this.lotSummary,
                currentBid: parseInt(currentBid),
                color: color,
                place_bid_amount: (current_bid_price.toString()),
                bidRemainingTime: rec.bid_remaining_time,
                end_date: new Date(rec.end_date * 1000),
                start_date: new Date(rec.start_date * 1000),
                status: statusList[rec.status],
                bid_price: (rec.your_bid),
                increment_slab: (rec.increment_slab),
                highest_price: rec.heighest_bid,
                floor_price: (rec.starting_price)
              }
            }
          }
        }).catch(err => {
          this.error = {
            id: "bid_price_error",
            message: err?.response?.data?.errors,
          };
        })
    },
    async detailsBidRefresh(bid_id) {
      let formData = new FormData();
      formData.append(`bid_master_ids[]`, bid_id)
      scbAxiosInstance.post('/all_bids_refersh', formData).then((res) => {
        if (res.status === 200) {
          const bidDetails = res.data.forward_bid_details[bid_id];
          if (bidDetails) {
            const bidColor = bidDetails.rank?.trim() ? ((["Winning", "Won"].indexOf(bidDetails.rank) > -1) ? 'win' : 'lose') : 'no-bid';
            this.setSuccess();
            this.lotSummary = {
              ...this.lotSummary,
              status: bidDetails.status,
              color: bidColor,
              highest_price: bidDetails.heighest_bid,
              bidRemainingTime: bidDetails.bid_remaining_time,
              rank: bidDetails.rank.trim() ? `(${bidDetails.rank.trim()})` : '',
              increment_slab: bidDetails.increment_slab,
              end_date: new Date(bidDetails.end_date * 1000),
              start_date: new Date(bidDetails.start_date * 1000),
              floor_price: bidDetails.starting_price,
              currentBid: bidDetails.heighest_bid ? bidDetails.heighest_bid : bidDetails.starting_price,
              bid_price: bidDetails.your_bid
            }

            this.openCartProduct = this.openCartProduct.map(product => {
              if (product.bid_id == bid_id) {
                return {
                  ...product,
                  status: bidDetails.status,
                  color: bidColor,
                  highest_price: bidDetails.heighest_bid,
                  bidRemainingTime: bidDetails.bid_remaining_time,
                  rank: bidDetails.rank.trim() ? `(${bidDetails.rank.trim()})` : '',
                  increment_slab: bidDetails.increment_slab,
                  end_date: new Date(bidDetails.end_date * 1000),
                  start_date: new Date(bidDetails.start_date * 1000),
                  floor_price: bidDetails.starting_price,
                  currentBid: bidDetails.heighest_bid ? bidDetails.heighest_bid : bidDetails.starting_price,
                  bid_price: bidDetails.your_bid
                }
              } else {
                return product;
              }
            })
          }
        }
      })
    },
    async getOpenLotDetail(id, bid_id, isInterval) {
      const loader = loaderStore();
      if (isInterval) {
        this.detailsBidRefresh(bid_id)
      } else {
        loader.setDetailsLoader(true);
        const rec = this.openCartProduct.find(a => a?.id == id)
        if (rec) {
          const lotDetails = formatLotDetails({
            bids_master: {
              ...rec,
              my_price: rec.bid_amount,
              increment_slab: rec.increment_slab,
              highest_price: rec.highest_price,
              currentBid: rec.currentBid,
              bid_remaining_time: rec.bidRemainingTime,
              shipping_type: rec.delivery_by,
              name: rec.lot_name, mrp: rec.bid_mrp, rank: rec.rank.replaceAll('(', '').replaceAll(')', '')
            },
            inventory: { medium_image_path: rec.lot_image_urls, storage_location: rec.storage_location, item_count: rec.items_count, grade_name: rec.grade_name, my_price: rec.bid_amount }
          })

          this.setSuccess();
          this.lotSummary = lotDetails.lot_summary;
          loader.setDetailsLoader(false);
        }

        const res = await fetchOpenLotProducts(id, bid_id, isInterval)
        loader.setDetailsLoader(false);
        if (res?.status === 200) {
          let formatedData = formatLotDetails(res.data);
          this.setSuccess();
          this.openCartProduct = this.openCartProduct.map(product => {
            if (product.id == id) {
              return {
                ...product,
                status: formatedData.lot_summary?.status,
                color: formatedData.lot_summary?.color,
                highest_price: formatedData.lot_summary?.highest_price,
                bidRemainingTime: formatedData.lot_summary?.bidRemainingTime,
                rank: formatedData.lot_summary?.rank,
                increment_slab: formatedData.lot_summary?.increment_slab,
                end_date: formatedData.lot_summary?.end_date,
                start_date: formatedData.lot_summary?.start_date,
                floor_price: formatedData.lot_summary?.floor_price,
                currentBid: formatedData.lot_summary?.currentBid,
                bid_price: formatedData.lot_summary?.bid_price
              }
            } else {
              return product
            }
          })

          this.lotSummary = formatedData.lot_summary;
          this.top_Brand = formatedData?.top_Brand?.filter(a => a.item_percentage > 0).sort((a, b) => (a.item_percentage > b.item_percentage) ? 1 : ((b.item_percentage > a.item_percentage) ? -1 : 0)).reverse() ?? [];
          this.top_Category = formatedData?.top_Category?.filter(a => a.item_percentage > 0).sort((a, b) => (a.item_percentage > b.item_percentage) ? 1 : ((b.item_percentage > a.item_percentage) ? -1 : 0)).reverse() ?? [];
        }
      }
    },
  },
  persist: {
    storage: sessionStorage
  }
});

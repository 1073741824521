import axios from "axios";
import { useAuth, loaderStore, useMetaStore } from "../stores"
import { passwordResetEndpoints, lotEndpoints } from "../static/index";
import { isDetailsPage } from "../misc/index";
import { remarketingUrl, scbUrl } from "../config.json";

const baseURL = remarketingUrl;
export const scbURl = scbUrl;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})
const scbAxiosInstance = axios.create({
  baseURL: scbURl,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosInstance.interceptors.request.use(config => {
  const authStore = useAuth()
  const metaStore = useMetaStore()
  const loaderState = loaderStore();
  const loaders = loaderState?.loaders || [];

  if (metaStore.marketplaceContent === 'private') {
    const separator = config.url.includes('?') ? '&' : '?';
    config.url += `${separator}marketplace_name=${metaStore.marketplaceName}`;
  }

  let urlDetails = [...loaders, {
    url: config.baseURL + config.url,
    method: config.method,
    loader: true,
    type: "request"
  }]
  config.headers = {
    ...config.headers,
    'Authorization': authStore.user?.authentication_token || "",
    "accesstoken": authStore.user?.authentication_token || "",
    "username": authStore.user?.username || "",
  }

  if ((lotEndpoints.indexOf(config.url.split('?')[0]) < 0) && !isDetailsPage(config.url)) {
    loaderState.setLoading(true, urlDetails);
  }
  return config
})

axiosInstance.interceptors.response.use(function (response) {
  const loaderState = loaderStore();

  let urlDetails = loaderState.remaingLoader({
    url: response?.config?.baseURL + response?.config?.url,
    method: response?.config?.method,
    loader: false,
    type: "response"
  })
  loaderState.setLoading(false, urlDetails);
  return response;
}, function (error) {
  const authStore = useAuth();
  const loaderState = loaderStore();

  let urlDetails = loaderState.remaingLoader({
    url: error?.config?.baseURL + error?.config?.url,
    method: error?.config?.method,
    loader: false,
    type: "error"
  })
  loaderState.setLoading(false, urlDetails);

  let errorMessage = ["Seller not present in the system."]

  if(error.response && errorMessage.includes(error.response.data.errors)){
    authStore.signOut(true)
    authStore.setLoginDialog(true)
  }

  if (error.response && error.response.config && error.response.status === 401) {
    authStore.signOut(true)
    authStore.setLoginDialog(true)
  } else {
    return Promise.reject(error)
  }
})

scbAxiosInstance.interceptors.request.use(config => {
  const authStore = useAuth()
  const loaderState = loaderStore();
  const loaders = loaderState.loaders || [];
  let urlDetails = [...loaders, {
    url: config.baseURL + config.url,
    method: config.method,
    loader: true,
    type: "request"
  }]

  if((lotEndpoints.indexOf(config.url.split('?')[0]) < 0) && !isDetailsPage(config.url)) {
    loaderState.setLoading(true , urlDetails);
  }

  config.headers = {
    ...config.headers,
    'Authorization': authStore.user?.authentication_token || "",
    "accesstoken": authStore.user?.authentication_token || "",
    "username": authStore.user?.username || "",
    "appversion": 2,
  }
  if(config.url === '/reseller/users/upload_documents' || config.url === '/all_bids_refersh')  {
    config.headers = {
      ...config.headers,
      'Content-Type': 'multipart/form-data'
    }
  }

  if (passwordResetEndpoints.indexOf(config.url) > -1) {
    config.headers = {
      ...config.headers,
      'accesstoken': authStore?.transactionToken || authStore.user?.authentication_token
    }
  }

  return config
})

scbAxiosInstance.interceptors.response.use(function (response) {
  const loaderState = loaderStore();
  let urlDetails = loaderState.remaingLoader({
    url: response?.config?.baseURL + response?.config?.url,
    method: response?.config?.method,
    loader: false,
    type: "response"
  })
  loaderState.setLoading(false, urlDetails);
  return response;
}, function (error) {
  const loaderState = loaderStore();
  const authStore = useAuth();
  let urlDetails = loaderState.remaingLoader({
    url: error?.config?.baseURL + error?.config?.url,
    method: error?.config?.method,
    loader: false,
    type: "error"
  })
  loaderState.setLoading(false, urlDetails);

  let errorMessage = ["Seller not present in the system."]

  if(error.response && errorMessage.includes(error.response.data.errors)){
    authStore.signOut(true)
    authStore.setLoginDialog(true)
  }
  if (error.response && error.response.config && error.response.status === 401) {
    authStore.signOut(true)
    authStore.setLoginDialog(true)
    return Promise.reject(error)
  } else {
    return Promise.reject(error)
  }
})
export { axiosInstance, scbAxiosInstance }

<template>
  <section class="faq">
    <v-container>
      <h6 class="faq-title mb-10 text-center"><u>Terms of purchase</u></h6>
      <h6 class="faq-ques">Price Value</h6>
      <p>The price value includes VAT and logistics costs.</p>
      <h6 class="faq-ques">Bid Value</h6>
      <p>
        Bid value is inclusive of GST however it doesn’t include the logistics
        cost.
      </p>
      <h6 class="faq-ques">Cancellation</h6>
      <p>
        Upon ordering a lot, it's crucial to fulfil the payment commitment.
        Failure to do so may lead to penalties or being blacklisted on the
        platform. Amazon (Seller) or Rebirch reserves the right, at our
        discretion, to refuse or cancel any ordered lot, notifying you promptly
        via email, phone, SMS, or WhatsApp. Refunds will be processed within 24
        hours of cancellation, or a credit not will be shared for the same.Upon
        ordering a lot, it's crucial to fulfil the payment commitment. Failure
        to do so may lead to penalties or being blacklisted on the platform.
        Amazon (Seller) or Rebirch reserves the right, at our discretion, to
        refuse or cancel any ordered lot, notifying you promptly via email,
        phone, SMS, or WhatsApp. Refunds will be processed within 24 hours of
        cancellation, or a credit not will be shared for the same.
      </p>

      <h6 class="faq-ques">Payment terms and purchase</h6>
      <p>
        Upon approval of your order for the relevant lot, you are required to
        make an advance payment of the total price/value within 24 hours.
        Failure to do so will result in cancellation of the order.
      </p>

      <p class="text-underline">
        Customer has to make the full payment before the processing of his order
        can begin. Partial payments will not be accepted.
      </p>

      <p>
        Subject to you making the payment of lot price/value within the
        above-mentioned period, you will receive the inventory within 15 to 20
        days. If you failed to receive the delivery from Amazon, inventory will
        be return to Amazon and your amount will be forfeited.
      </p>

      <h6 class="faq-ques">Item Condition</h6>
      <p>
        Post payment for the won lot, buyer will receive the invoice and
        information for inventory pickup. Logistics cost has to be borne by the
        buyer over and above the bid value.
      </p>
      <h6 class="faq-ques">Claims:</h6>
      <p>
        The condition of Amazon inventory will be specified as either "Brand new
        seal packed" or "Customer returned." In the second case, the products
        may be functionally good with minor or major scratches, defective, or in
        damaged condition. The inventory will be shipped to the buyer in the
        same condition as described.
      </p>

      <h6 class="faq-ques">Shipment</h6>
      <p>
        After payment for the ordered lot, the buyer will receive the invoice
        and information regarding inventory delivery. The price value includes
        logistics costs.
      </p>
    </v-container>
  </section>
</template>

import { scbAxiosInstance, axiosInstance } from "../services";
import { useAlertStore } from "../stores";
import { useMetaStore } from "../stores/metastore.store";
import configuration from "../config.json";

var baseurl;
var axios;
export const getOTP = async (data = {}) => {
    const resp = await scbAxiosInstance.post("/api/buyers/send_mobile_otp", data)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}

export const getOTPOnEmail = async (data = {}) => {
    const resp = await scbAxiosInstance.post("/api/buyers/send_email_mobile_otp", data)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}

export const register = async (data = {}) => {
    const resp = await scbAxiosInstance.post("/api/buyers/buyer_registration", data)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}

export const verifyOTP = async (data = {}) => {
    const resp = await scbAxiosInstance.post("/api/buyers/submit_mobile_otp", data)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}

export const verifyForgotPasswordOTP = async (data, transactionToken = "") => {
  const alertStore = useAlertStore();
  const metaStore = useMetaStore();
  if(metaStore.marketplaceContent === 'private' || configuration.new_signin.enabled){
    baseurl = "/api/users/verify_otp"
    axios = axiosInstance
  }else{
    baseurl = '/reseller/users/verify_otp'
    axios = scbAxiosInstance
    data.input = data.mobile
  }
    const resp = await axios.post(baseurl, data)
    .then(res => {
      return res;
    }).catch(error => {
      return error.response
    })
    return resp;
}

export const verifyLoginOTP = async (data) => {
    const alertStore = useAlertStore();
    const metaStore = useMetaStore();
    if(metaStore.marketplaceContent === 'private' || configuration.new_signin.enabled){
      baseurl = "/api/users/sign_in_with_otp"
      axios = axiosInstance
    }else{
      baseurl = '/api/users/login_through_otp'
      axios = scbAxiosInstance
      data.input = data.mobile
    }
    const resp = await axios.post(baseurl, data)
    .then(res => {
        if (res.status === 200) {
          alertStore.setAlertMessage('success', 'Signed in successfully.', true)
        }        
        return res;
    }).catch(error => {
        return error.response
    })
    return resp;
}

export const forgotPassword = async (data) => {
  const metaStore = useMetaStore();
  if(metaStore.marketplaceContent === 'private' || configuration.new_signin.enabled){
    baseurl = "/api/users/forgot_password"
    axios = axiosInstance
  }else{
    baseurl = "/reseller/users/forgot_password"
    axios = scbAxiosInstance
    data.input = data.mobile
  }

  const resp = await axios.post(baseurl, data).then(res => {
    return res;
  }).catch(error => {
    return error.response
  })
  return resp;
}

export const changePassword = (data) => {
  const metaStore = useMetaStore();
  if(metaStore.marketplaceContent === 'private' || configuration.new_signin.enabled){
    baseurl = "/api/users/change_password.json"
    axios = axiosInstance
  }else{
    baseurl = "/reseller/users/change_password.json"
    axios = scbAxiosInstance
  }
    const resp = axios.post(baseurl, data).then(res => {
        return res;
    }).catch(error => {
        return error.response
    })
    return resp;
}

export const resetPassword = (data) => {
    const metaStore = useMetaStore();
    if(metaStore.marketplaceContent === 'private' || configuration.new_signin.enabled){
      baseurl = "/api/users/reset_password.json"
      axios = axiosInstance
    }else{
      baseurl = "/reseller/users/reset_password.json"
      axios = scbAxiosInstance
    }

    if (metaStore.marketplaceContent !== 'private') {
      delete data.user.mobile;
      delete data.user.transaction_token;
    }
    const resp = axios.post(baseurl, data).then(res => {
      return res;
    }).catch(error => {
      return error.response
    })
    return resp;
}
// src/stores/metaStore.js
import { defineStore } from 'pinia';

export const useMetaStore = defineStore('meta', {
  state: () => ({
    marketplaceContent: '',
    marketplaceName: '',
    marketplaceLogo: '',
  }),
  actions: {
    setmarketplaceContent(content) {
      this.marketplaceContent = content;
    },
    setmarketplaceName(name) {
      this.marketplaceName = name;
    },
    setmarketplaceLogo(logo) {
      this.marketplaceLogo = logo;
    }
  }
});

import { defineStore } from "pinia";
export const loaderStore = defineStore({
  id: "loader",
  state: () => ({
    loading: false,
    loaders: [],
    detailsLoader: false,
    cartLoader: false,
    productsLoader: false, 
    lotLoader: false
  }),
 getters : {
    remaingLoader: (state) =>{
      return (detail) => state?.loaders?.filter(loader => loader.url !== detail.url) || [];
    }
 },
  actions: {
    setProductsLoader(value) {
      this.productsLoader = value;
    },
    setDetailsLoader(value) {
      this.detailsLoader = value;
    },
    setCartLoader(value) {
      this.cartLoader = value
    },
    setLoading(value , loaderDetail) {
          this.loaders = loaderDetail
      if(value){
        this.loading = true
      }else{
        if(loaderDetail?.length === 0){
          this.loading = false
        }else {
          this.loaders = loaderDetail
        }

      }
    },
    setLotLoading(value) {
      this.lotLoader = value;
    }
  }
})
